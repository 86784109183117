@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap');

body {
    margin:0px;
    padding:0px;
    font-family: "Inter";
    font-size: 0.9rem;
}

a {
    text-decoration: none;
}